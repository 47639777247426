<template>
    <div class="dialog-container" v-if="show">
        <div style="position: relative; width: 100%; height: 100%">
            <div class="dialog">
                <span class="dialog-title">Información</span>
                <span class="dialog-message">
                    No se han encontrado aditivas en el rango de fecha seleccionada
                </span>
                <div class="row justify-content-end mt-5">
                    <div class="col-auto">
                        <button class="btn btn-outline-danger" @click="hideDialog">
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, inject, provide } from "@vue/runtime-core"
//import { useRouter } from "vue-router"

export default defineComponent({
    name: 'NoRegistros',
    setup() {
        //const router = useRouter()

        const show = inject("IS_VISIBLE_ERROR_RECORD_SEARCH_DIALOG")

        provide("IS_VISIBLE_ERROR_RECORD_SEARCH_DIALOG", show)

        const hideDialog = () => {
            show.value = false
        }

        return { show, hideDialog }
    }
})
</script>

<style scoped>
    div.dialog-container {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 11;
    }

    div.dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border-radius: 0.5em;
        padding: 1em 2em;
        max-width: 80%;
    }

    span.dialog-title {
        display: block;
        font-size: 1.5em;
        font-weight: 700;
    }
</style>