
<template>
    <nav class="navbar navbar-default navbar-fixed-top">
        <div class="container-fluid">
            <p class="navbar-text">Aditivas al presupuesto</p>
            <a class="navbar-brand" href="#">
                <img alt="Logo" src="../assets/logo_gig.png">
            </a>
        </div>
    </nav>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavBar",
  setup() {

    return {  };
  },
});
</script>

<style scoped>

.navbar {
    padding: 0;
    background-color: #0275d8;
    margin-bottom: 2em;
}

img {
    width: 6em;
}

p {
    font-size: 2em;
    color: white;
    
}

</style>