<template>
  <div class="container-fluid">
    <!-- <div class="row seccion">
        <div class="col">
            <div class="row">
                <div class="col">
                    <h1>Aditivas al presupuesto</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <img class="logo" src="../assets/logo_gig.png" alt="Logo GIG">
                </div>
            </div>
        </div>
        <div class="col">
            <h4>Total solicitado</h4>
        </div>
    </div> -->
    <div class="row">
        <div class="col">
            <NoRegistros />
        </div>
    </div>
    <div class="row justify-content-end">
        <div class="col-4">
            <span></span>
        </div>
        <div class="col-8">
            <label class="filtro" for="filtroFechaIni">Fecha Inicio</label>
            <input class="filtro" type="date" id="filtroFechaIni" name="filtroFechaIni" v-model="fechaConsultaIni"> <!-- @keypress.enter="filtrarPorFecha"> -->
        <!-- </div>
        <div class="col-4"> -->
            <label class="filtro" for="filtroFechaFin">Fecha Fin</label>
            <input class="filtro" type="date" id="filtroFechaFin" name="filtroFechaFin" v-model="fechaConsultaFin"> <!-- @keypress.enter="filtrarPorFecha"> -->
            <button :disabled="!fechaConsultaFin && !fechaConsultaIni" class="btn btn-primary filtro" type="button" v-on:click="filtrarPorFecha"><i class="bi bi-search"></i>Filtrar</button>
        </div>
    </div>
    <div class="row seccion">
        <div class="col">
        <!-- <table class="table table-striped">
            <thead>
                <tr>
                    <th>Datos del solicitante</th>
                    <th>ID aditiva</th>
                    <th>Elemento PEP</th>
                    <th>Tipo de gasto</th>
                    <th>Servicio</th>
                    <th>Justificación</th>
                    <th>Importe de la solicitud</th>
                    <th>IVA</th>
                    <th>Presupuesto disponible</th>
                    <th>Total aditiva aprobada</th>
                    <th>Estatus</th>
                    <th>Estatus autorizador</th>
                    <th>Fecha de solicitud</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="aditiva in aditivas" :key="aditiva.id_aditiva">
                    <td>
                        {{ aditiva['SOLICITANTE'] }}
                    </td>
                    <td>
                        {{ aditiva['ID_ADITIVA'] }}
                    </td>
                    <td>
                        {{ aditiva['PEP'] }}
                    </td>
                    <td>
                        {{ aditiva['TIPO_GASTO'] }}
                    </td>
                    <td>
                        {{ aditiva['SERVICIO'] }}
                    </td>
                    <td>
                        {{ aditiva['JUSTIFICACION'] }}
                    </td>
                    <td>
                        {{ aditiva['IMPORTE'] }}
                    </td>
                    <td>
                        {{ aditiva['IVA'] }}
                    </td>
                    <td>
                        {{ aditiva['PPTO_DISPONIBLE'] }}
                    </td>
                    <td>
                        {{ aditiva['TOTAL'] }}
                    </td>
                    <td>
                        {{ aditiva['ESTATUS'] }}
                    </td>
                    <td>
                        {{ aditiva['ESTATUS_AUTORIZADOR'] }}
                    </td>
                    <td>
                        {{ aditiva['FECHA_SOLICITUD'] }}
                    </td>
                </tr>
            </tbody>
        </table> -->

        <Vue3EasyDataTable :headers="campos" :items="aditivas" multi-sort/>

        </div>
    </div>
    <div class="row">
        <div class="col-8">
            <span></span>
        </div>
        <div class="col-4">
            <button class="btn btn-success descargar" @click="descargarExcel">Descargar Excel</button>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ref, onBeforeMount, provide } from 'vue'
import NoRegistros from '@/components/NoRegistros.vue'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'
import xlsx from 'json-as-xlsx'

export default {
  name: 'Main',
  components: {
    NoRegistros,
    Vue3EasyDataTable
  },
  setup() {
    
    const aditivas = ref([])
    const fechaConsultaIni = ref(null)
    const fechaConsultaFin = ref(null)
    const noRegistrosVisible = ref(false)
    const campos = ref([
        {
            text: 'Solicitante',
            value: 'SOLICITANTE',
            sortable: true
        },
        {
            text: 'ID aditiva',
            value: 'ID_ADITIVA',
            sortable: true
        },
        {
            text: 'PEP',
            value: 'PEP',
            sortable: true
        },
        {
            text: 'Tipo gasto',
            value: 'TIPO_GASTO',
            sortable: true
        },
        {
            text: 'Servicio',
            value: 'SERVICIO',
            sortable: true
        },
        {
            text: 'Justificación',
            value: 'JUSTIFICACION',
            sortable: true
        },
        {
            text: 'Importe solicitado',
            value: 'IMPORTE',
            sortable: true
        },
        {
            text: 'IVA',
            value: 'IVA',
            sortable: true
        },
        {
            text: 'Prespuesto disponible',
            value: 'PPTO_DISPONIBLE',
            sortable: true
        },
        {
            text: 'Total aditiva',
            value: 'TOTAL',
            sortable: true
        },
        {
            text: 'Estatus',
            value: 'ESTATUS',
            sortable: true
        },
        {
            text: 'Estatus autorizador 1',
            value: 'ESTATUS_AUTORIZADOR1',
            sortable: true
        },
        {
            text: 'Autorizador 1',
            value: 'AUTORIZADOR1',
            sortable: true
        },
        {
            text: 'Estatus autorizador 2',
            value: 'ESTATUS_AUTORIZADOR2',
            sortable: true
        },
        {
            text: 'Autorizador 2',
            value: 'AUTORIZADOR2',
            sortable: true
        },
        {
            text: 'Estatus autorizador 3',
            value: 'ESTATUS_AUTORIZADOR3',
            sortable: true
        },
        {
            text: 'Autorizador 3',
            value: 'AUTORIZADOR3',
            sortable: true
        },
        {
            text: 'Fecha solicitud',
            value: 'FECHA_SOLICITUD',
            sortable: true
        },
    ])
    const numeroPorPagina = ref(10)

    provide("IS_VISIBLE_ERROR_RECORD_SEARCH_DIALOG", noRegistrosVisible)

    const filtrarPorFecha = async() => {
        if(fechaConsultaIni.value > fechaConsultaFin.value) {
            alert('La fecha inicial no puede ser mayor a la fecha final')
        } else if(fechaConsultaIni.value && fechaConsultaFin.value) {
            axios
            .get('https://aplicaciones.gig.mx/aditivasestatusqas/service.php?fecha_ini=' + fechaConsultaIni.value + '&fecha_fin=' + fechaConsultaFin.value)
            .then((response) => {
                if(response.data['ET_ADITIVAS']['item']) {
                    response.data['ET_ADITIVAS']['item'].forEach(formatearEstatus)
                    response.data['ET_ADITIVAS']['item'].forEach(formatearMoneda)
                }
                aditivas.value = response.data['ET_ADITIVAS']['item']
                if(!aditivas.value) {
                    noRegistrosVisible.value = true
                }
            })
        }
    }

    onBeforeMount( async () => {
        var hoy = new Date()
        var fechaInicio = new Date(hoy.getTime() - (60 * 24 * 60 * 60 * 1000))
        fechaConsultaIni.value = fechaInicio.getFullYear() + '-' + String(fechaInicio.getMonth() + 1).padStart(2, '0') + '-' + String(fechaInicio.getDate()).padStart(2, '0')
        fechaConsultaFin.value = hoy.getFullYear() + '-' + String(hoy.getMonth() + 1).padStart(2, '0') + '-' + String(hoy.getDate()).padStart(2, '0')
        axios
        .get('https://aplicaciones.gig.mx/aditivasestatusqas/service.php?fecha_ini=' + 
            fechaInicio.getFullYear() + '-' + String(fechaInicio.getMonth() + 1).padStart(2, '0') + '-' + String(fechaInicio.getDate()).padStart(2, '0') + '&fecha_fin=' + fechaConsultaFin.value
        )
        .then((response) => {
            if(response.data['ET_ADITIVAS']['item']) {
                    response.data['ET_ADITIVAS']['item'].forEach(formatearEstatus)
                    response.data['ET_ADITIVAS']['item'].forEach(formatearMoneda)
                }
            aditivas.value = response.data['ET_ADITIVAS']['item']
            if(!aditivas.value) {
                noRegistrosVisible.value = true
            }
        })
    
    });

    const formatearEstatus = (aditiva) => {
        if(aditiva.ESTATUS == 'P') {
            aditiva.ESTATUS = 'Pendiente'
        }
        if(aditiva.ESTATUS == 'A') {
            aditiva.ESTATUS = 'Aprobado'
        }
        if(aditiva.ESTATUS == 'R') {
            aditiva.ESTATUS = 'Rechazado'
        }
        if(aditiva.ESTATUS == 'S') {
            aditiva.ESTATUS = 'Sin autorización requerida'
        }

        if(aditiva.ESTATUS_AUTORIZADOR1 == 'P') {
            aditiva.ESTATUS_AUTORIZADOR1 = 'Pendiente'
        }
        if(aditiva.ESTATUS_AUTORIZADOR1 == 'A') {
            aditiva.ESTATUS_AUTORIZADOR1 = 'Aprobado'
        }
        if(aditiva.ESTATUS_AUTORIZADOR1 == 'R') {
            aditiva.ESTATUS_AUTORIZADOR1 = 'Rechazado'
        }
        if(aditiva.ESTATUS_AUTORIZADOR1 == 'S') {
            aditiva.ESTATUS_AUTORIZADOR1 = 'Sin autorización requerida'
        }

        if(aditiva.ESTATUS_AUTORIZADOR2 == 'P') {
            aditiva.ESTATUS_AUTORIZADOR2 = 'Pendiente'
        }
        if(aditiva.ESTATUS_AUTORIZADOR2 == 'A') {
            aditiva.ESTATUS_AUTORIZADOR2 = 'Aprobado'
        }
        if(aditiva.ESTATUS_AUTORIZADOR2 == 'R') {
            aditiva.ESTATUS_AUTORIZADOR2 = 'Rechazado'
        }
        if(aditiva.ESTATUS_AUTORIZADOR2 == 'S') {
            aditiva.ESTATUS_AUTORIZADOR2 = 'Sin autorización requerida'
        }

        if(aditiva.ESTATUS_AUTORIZADOR3 == 'P') {
            aditiva.ESTATUS_AUTORIZADOR3 = 'Pendiente'
        }
        if(aditiva.ESTATUS_AUTORIZADOR3 == 'A') {
            aditiva.ESTATUS_AUTORIZADOR3 = 'Aprobado'
        }
        if(aditiva.ESTATUS_AUTORIZADOR3 == 'R') {
            aditiva.ESTATUS_AUTORIZADOR3 = 'Rechazado'
        }
        if(aditiva.ESTATUS_AUTORIZADOR3 == 'S') {
            aditiva.ESTATUS_AUTORIZADOR3 = 'Sin autorización requerida'
        }
    }

    const formatearMoneda = (aditiva) => {
        //for (const aditiva in aditivas.value) {
        let valor = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN'
        })
        aditiva.IMPORTE = valor.format(aditiva.IMPORTE)
        aditiva.PPTO_DISPONIBLE = valor.format(aditiva.PPTO_DISPONIBLE)
        aditiva.TOTAL = valor.format(aditiva.TOTAL)
    }

    const descargarExcel = () => {

        const content = 
        [
            {
                sheet: 'Aditivas',
                columns: [
                    {
                        label: 'Solicitante',
                        value: 'SOLICITANTE'
                    },
                    {
                        label: 'ID aditiva',
                        value: 'ID_ADITIVA'
                    },
                    {
                        label: 'PEP',
                        value: 'PEP'
                    },
                    {
                        label: 'Tipo gasto',
                        value: 'TIPO_GASTO'
                    },
                    {
                        label: 'Servicio',
                        value: 'SERVICIO'
                    },
                    {
                        label: 'Justificación',
                        value: 'JUSTIFICACION'
                    },
                    {
                        label: 'Importe solicitado',
                        value: 'IMPORTE'
                    },
                    {
                        label: 'IVA',
                        value: 'IVA',
                    },
                    {
                        label: 'Prespuesto disponible',
                        value: 'PPTO_DISPONIBLE'
                    },
                    {
                        label: 'Total aditiva',
                        value: 'TOTAL'
                    },
                    {
                        label: 'Estatus',
                        value: 'ESTATUS'
                    },
                    {
                        label: 'Estatus autorizador 1',
                        value: 'ESTATUS_AUTORIZADOR1'
                    },
                    {
                        label: 'Autorizador 1',
                        value: 'AUTORIZADOR1'
                    },
                    {
                        label: 'Estatus autorizador 2',
                        value: 'ESTATUS_AUTORIZADOR2'
                    },
                    {
                        label: 'Autorizador 2',
                        value: 'AUTORIZADOR2'
                    },
                    {
                        label: 'Estatus autorizador 3',
                        value: 'ESTATUS_AUTORIZADOR3'
                    },
                    {
                        label: 'Autorizador 3',
                        value: 'AUTORIZADOR3'
                    },
                    {
                        label: 'Fecha solicitud',
                        value: 'FECHA_SOLICITUD'
                    }
                ],
                content: JSON.parse(JSON.stringify(aditivas.value))
            }
        ]

        var settings = {
            fileName: 'Aditivas'
        }

        xlsx(content, settings)
    }

    return {
        aditivas,
        fechaConsultaIni,
        fechaConsultaFin,
        filtrarPorFecha,
        formatearMoneda,
        formatearEstatus,
        descargarExcel,
        noRegistrosVisible,
        campos,
        numeroPorPagina
    }
  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .logo {
        width: 20em;
    }
    /*table{
        border: none;
        padding: 0;
        border-spacing: 0;
    }*/
    td {
        word-wrap: break-word;
        word-break: break-all;
    }
    th {
        background-color: #099ae3 !important;
    }
    .seccion {
        margin-top: 2em;
    }
    .filtro {
        margin-right: 0.3em;
    }

    .descargar {
        margin-top: 2em;
        margin-bottom: 2em;
    }

</style>
